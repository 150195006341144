@font-face {
    font-family: Poppins-Medium;
    src: url('./Poppins-Medium.ttf'); 
}

@font-face {
    font-family: Poppins-Regular;
    src: url('./Poppins-Regular.ttf'); 
}

*{
    font-family: Poppins-Regular;
}